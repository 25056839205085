.loader {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 8px;
}

.loader span {
  position: absolute;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}

.loader.green span {
  background: #37bd72;
}

.loader.white span {
  background: #fff;
}

.loader.black span {
  background: #000;
}

.loader span:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}

.loader span:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}

.loader span:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}

.loader span:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}

@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}

@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}
