.container {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  box-sizing: border-box;
  padding-top: 12px;
}

.menu {
  width: 220px;
  height: 100%;
  flex-shrink: 0;
  padding: 18px 12px 24px 12px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  border-right: solid 1px rgba(0, 0, 0, 0.2);
}

.bottomMenu {
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  gap: 12px;
}

.link {
  padding: 12px 0;
  transition: opacity 0.3s ease;
}

.link.active > span {
  font-weight: 700;
}

.link:hover {
  opacity: 0.7;
  text-decoration: none;
}

.content {
  flex: 1;
  padding: 24px;
  height: 100%;
  overflow: auto;
}

.tooltip {
  z-index: 199;
}

@media (max-width: 500px) {
  .container {
    flex-direction: column;
  }

  .menu {
    width: 100%;
    height: auto;
    flex-direction: row;
    border: none;
    border-bottom: solid 1px rgba(0, 0, 0, 0.2);
    justify-content: space-between;
    flex-wrap: wrap;
  }

  .bottomMenu {
    min-width: 100%;
  }

  .link {
    margin: 0 12px;
  }
}
