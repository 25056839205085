.container {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
}

.content {
  display: flex;
  flex: 1;
  flex-direction: column;
  box-sizing: border-box;
}

.footer {
  width: 100%;
  display: flex;
  position: relative;
}

.footer:after {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 1px;
  background-color: #fff;
  z-index: -1;
}

.indicatorWrapper {
  display: flex;
  flex-direction: column;
  flex: 1;
  position: relative;
}

.indicatorLabel {
  margin: 8px;
  text-align: center;
  line-height: 1.5;
}

.indicatorLabelActive {
  opacity: 1;
}

.indicatorWrapperComplete .indicator {
  background-color: #fff;
}

.indicator {
  width: 100%;
  height: 3px;
  position: absolute;
  top: -1px;
}

:global(.fade-enter) {
  opacity: 0;
}

:global(.fade-enter-active).content {
  opacity: 1;
}

:global(.fade-exit).content {
  opacity: 1;
}

:global(.fade-exit-active).content {
  opacity: 0;
}

:global(.fade-enter-active).content,
:global(.fade-exit-active).content {
  transition: opacity 500ms;
}

@media (max-width: 800px) {
  .content {
    padding: 78px 48px;
  }
}

@media (max-width: 500px) {
  .content {
    padding: 48px 24px;
  }

  .footer {
    display: none;
  }
}
