.slider {
  width: 100%;
  height: 4px;
  border-radius: 2px;
  overflow-x: hidden;
  position: relative;
}

.line {
  position: absolute;
  width: 100%;
  height: 6px;
}

.slider.light .line {
  background: rgba(255, 255, 255, 0.4);
}

.subline {
  position: absolute;
  height: 4px;
  border-radius: 2px;
}

.slider.light .subline {
  background: #fff;
}

.slider.dark .subline {
  background: #333333;
}

.inc {
  animation: increase 2s infinite;
}

.dec {
  animation: decrease 2s 0.5s infinite;
}

@keyframes increase {
  from {
    left: -5%;
    width: 5%;
  }
  to {
    left: 130%;
    width: 100%;
  }
}
@keyframes decrease {
  from {
    left: -80%;
    width: 80%;
  }
  to {
    left: 110%;
    width: 10%;
  }
}
