.container {
  width: 100%;
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  padding: 36px 24px;
  box-sizing: border-box;
}

.content {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  max-width: 470px;
  width: 100%;
}

.headline {
  margin-bottom: 24px;
}

.action {
  margin-top: 42px;
}

.dropzone {
  width: 100%;
  height: 100%;
  transition: background-color 1s;
  display: flex;

  box-sizing: border-box;
}

.icon {
  margin-bottom: 12px;
}

.wrapper {
  margin: 5px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  flex: 1;

  border: 1px solid white;
  border-radius: 5px;
}
