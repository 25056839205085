.container {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.instructions {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.instructions .features {
  margin-top: 25px;
  text-align: center;
}

.noWrap {
  display: inline-block;
}

.instructions h3.actions {
  line-height: 1.2;
  display: flex;
}

.actions {
  margin-top: 60px;
  display: flex;
  flex-direction: column;
}

.uploadButton {
  display: flex;
  align-items: center;
}

.uploadButton + .uploadButton {
  margin-top: 18px;
}

.icon {
  fill: #fff;
  width: 24px;
  height: 24px;
  margin-right: 12px;
}

.icon.encrypted {
  fill: #000;
}
