.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
}

.headline {
  margin-bottom: 60px;
  letter-spacing: 0.03em;
  display: flex;
  align-items: center;
}

.errorHeadline {
  letter-spacing: 0.03em;
  display: flex;
  align-items: center;
  margin-bottom: 24px;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.loader {
  margin-right: 12px;
}

.progress {
  max-width: 470px;
  margin: 40px auto;
  width: 100%;
}

.action {
  margin-top: 42px;
}
