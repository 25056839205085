.container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.close {
  background-color: transparent;
  border: 0;
  margin: 0;
  cursor: pointer;
}

.closeBox {
  width: 28px;
  height: 24px;
  display: inline-block;
  position: relative;
}

.closeInner {
  display: block;
  top: 50%;
  margin-top: -2px;
  transform: rotate(-45deg);
}

.closeInner,
.closeInner::before,
.closeInner::after {
  width: 30px;
  height: 2px;
  border-radius: 4px;
  position: absolute;
  transition-property: transform;
  transition-duration: 0.15s;
  transition-timing-function: ease;
}

.closeInner::before,
.closeInner::after {
  content: '';
  display: block;
  background-color: #fff;
}

.closeInner::before {
  top: 0;
}

.closeInner::after {
  bottom: 0;
  transform: rotate(-90deg);
}

.mailboxInfo {
  margin-top: 24px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1;
  overflow: auto;
}

.infoRow {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  min-width: 320px;
  width: 100%;
  padding: 24px 0;
  box-sizing: border-box;
}

.infoRow + .infoRow {
  border-top: solid 1px rgba(204, 204, 204, 0.5);
}

.label {
  margin-right: 12px;
}

.address {
}

.addressWrapper {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex: 1;
}

.infoRow .addressWrapper .addressInput {
  color: #fff;
  max-width: 300px;
}

.copyAddress {
  margin-left: 12px;
}

.qrLabelWrapper {
  flex-grow: 0;
  display: flex;
  flex-direction: column;
  max-width: calc(100% - 200px);
}

.qrLabelWrapper .label {
  margin-bottom: 6px;
}

.qr {
  align-items: flex-start;
}
