.container {
  width: 100%;
  height: 100%;

  display: flex;
  align-items: center;
  gap: 30px;

  padding: 36px 124px;
  box-sizing: border-box;
  background-color: rgba(0, 0, 0, 0.4);
  position: relative;
}

.container:after {
  position: absolute;
  inset: 0;
  content: '';
  z-index: -1;
  background: url(./assets/background.jpg) no-repeat center / cover;
}

.logo {
  position: absolute;
  top: 24px;
  left: 24px;
}

.card {
  background-color: #ffffff;
  padding: 24px;
  border-radius: 6px;
  width: 280px;
  height: 320px;
  box-sizing: border-box;
  overflow: hidden;
}

.claimWrapper {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
