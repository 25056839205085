.container {
  width: 100%;
  height: 100%;
}

.header {
  padding: 6px 0;
}

.rowWrapper {
  display: grid;
  grid-template-columns: 4fr repeat(3, 1fr);
  grid-column-gap: 6px;
}

.rowWrapper + .rowWrapper {
  margin-top: 6px;
  border-top: solid 1px rgba(0, 0, 0, 0.1);
}

.row {
  padding: 6px 0;
  display: flex;
  align-items: center;
}

.icon {
  margin-right: 12px;
}
