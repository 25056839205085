.container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 36px 24px;
  box-sizing: border-box;
}

.content {
  width: 100%;
  max-width: 460px;
  display: flex;
  flex-direction: column;
}

.headline {
  margin-bottom: 60px;
}

.decorator {
  width: 1px;
  height: 42px;
  background-color: #ccc;
  display: block;
  align-self: center;
}

.actions {
  margin-top: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 470px;
}

.submitButton {
  min-width: 150px;
}

.error {
  margin-top: 24px;
}

@media (max-width: 400px) {
  .actions {
    flex-direction: column;
  }

  .actions .submitButton {
    margin-bottom: 24px;
  }

  p.error {
    text-align: center;
  }
}
