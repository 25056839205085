/*!
 * Hamburgers
 * @description Tasty CSS-animated hamburgers
 * @author Jonathan Suh @jonsuh
 * @site https://jonsuh.com/hamburgers
 * @link https://github.com/jonsuh/hamburgers
 */
.hamburger {
  padding: 1px 1px;
  display: inline-block;
  cursor: pointer;
  transition-property: opacity, filter;
  transition-duration: 0.15s;
  transition-timing-function: linear;
  font: inherit;
  color: inherit;
  text-transform: none;
  background-color: transparent;
  border: 0;
  margin: 0;
  overflow: visible;
}
.hamburger.isActive .hamburgerInner,
.hamburger.isActive .hamburgerInner::before,
.hamburger.isActive .hamburgerInner::after {
  background-color: #000;
}

.hamburger {
  opacity: 1;
  transition: opacity 1s;
}

.nav-disabled .hamburger {
  pointer-events: none;
}

.hamburgerBox {
  width: 40px;
  height: 24px;
  display: inline-block;
  position: relative;
}

.hamburgerInner {
  display: block;
  top: 50%;
  margin-top: -2px;
}

.hamburgerInner,
.hamburgerInner::before,
.hamburgerInner::after {
  width: 30px;
  height: 2px;
  background-color: #000;
  border-radius: 4px;
  position: absolute;
  transition-property: transform;
  transition-duration: 0.15s;
  transition-timing-function: ease;
}
.hamburgerInner::before,
.hamburgerInner::after {
  content: '';
  display: block;
}
.hamburgerInner::before {
  top: -10px;
}
.hamburgerInner::after {
  bottom: -10px;
}

/* Variants */
.black .hamburgerInner,
.black .hamburgerInner::before,
.black .hamburgerInner::after {
  background-color: #000;
  transition: transform 0.15s, color 1s;
}

.white .hamburgerInner,
.white .hamburgerInner::before,
.white .hamburgerInner::after {
  background-color: #fff;
  transition: transform 0.15s, color 1s;
}

/*
* Spin
*/
.hamburgerSpin .hamburgerInner {
  transition-duration: 0.22s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
}
.hamburgerSpin .hamburgerInner::before {
  transition: top 0.1s 0.25s ease-in, opacity 0.1s ease-in;
}
.hamburgerSpin .hamburgerInner::after {
  transition: bottom 0.1s 0.25s ease-in, transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburgerSpin.isActive .hamburgerInner {
  transform: rotate(225deg);
  transition-delay: 0.12s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}
.hamburgerSpin.isActive .hamburgerInner::before {
  top: 0;
  opacity: 0;
  transition: top 0.1s ease-out, opacity 0.1s 0.12s ease-out;
}
.hamburgerSpin.isActive .hamburgerInner::after {
  bottom: 0;
  transform: rotate(-90deg);
  transition: bottom 0.1s ease-out, transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
}

.hamburger.isActive .hamburgerInner,
.hamburger.isActive .hamburgerInner::before,
.hamburger.isActive .hamburgerInner::after {
  background-color: #fff;
}

.is-selecting .hamburger {
  pointer-events: none;
  opacity: 0;
  transition: opacity 1s;
}
