.button {
  border: none;
  outline: none;
  background: transparent;
  cursor: pointer;
  transition: opacity 0.3s ease;
}

.button:hover {
  opacity: 0.7;
}

.icon {
  width: 18px;
  height: 18px;
}
