.container {
  width: 100%;
  padding: 20px 20px 0 80px;
  display: flex;
  align-items: center;
  box-sizing: border-box;
}

.container .version {
  margin-left: 20px;
}

.actions {
  flex: 1;
  display: flex;
  justify-content: flex-end;
}

.login {
  line-height: 24px;
}

.login:hover {
  color: #fff;
  text-decoration: underline;
}

.actions .profileButton {
  padding: 2px 12px;
  height: auto;
}

.actions .logoutButton {
  padding: 2px 12px;
  height: auto;
  margin-left: 12px;
}

@media (max-width: 500px) {
  .version {
    display: none;
  }

  .actions {
    display: none;
  }
}
