.container {
  width: 100%;
  height: 100%;
}

.content {
  display: grid;
  grid-template-columns: 4fr repeat(3, 1fr);
  grid-column-gap: 6px;
  grid-row-gap: 6px;
}

.header {
  padding: 6px 0;
  border-bottom: solid 1px rgba(0, 0, 0, 0.1);
}

.row {
  padding: 6px 0;
}
