.container {
  position: relative;
  height: 110px;
  width: 100px;
  background: #111;
  margin: 1px;
  clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);
  transition: 2s;
}

.container:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 50%;
  height: 100%;
  pointer-events: none;
  background: rgba(255, 255, 255, 0.02);
}

.container:hover {
  transition: 0s;
  background: #0f0;
  cursor: alias;
}
