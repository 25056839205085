.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
}

.headline {
  margin-bottom: 60px;
  letter-spacing: 0.03em;
}

.content {
  width: 100%;
  max-width: 470px;
}

.error {
  margin-top: 24px;
}

.actions {
  margin-top: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 470px;
}
