.button {
  border-radius: 18px;
  font-size: 16px;
  padding: 5px 25px 4px;
  border: none;
  height: 36px;
  background-color: #fff;
  font-weight: 400;
  user-select: none;
  transition: opacity 0.3s ease-in-out, color 0.3s ease-in-out, background-color 0.3s ease-in-out,
    border-color 0.3s ease-in-out;
  cursor: pointer;
  border: solid 1px transparent;
}

.button:hover {
  opacity: 0.7;
}

.green {
  background-color: #fff;
  color: #37bd72;
}

.black {
  background-color: #000;
  color: #fff;
}

.white {
  background-color: #fff;
  color: #2e332f;
}

.red {
  background-color: #fb4a36;
  color: #fff;
  font-weight: 600;
}

.transparent {
  background-color: transparent;
  color: #fff;
}

.button[disabled] {
  opacity: 0.3;
}

.inverted.black {
  background-color: transparent;
  border-color: #000;
  color: #000;
}

.button.inverted.black:hover {
  opacity: 1;
  background-color: #000;
  color: #fff;
}

.inverted.green {
  background-color: transparent;
  border-color: #37bd72;
  color: #37bd72;
}

.button.inverted.green:hover {
  opacity: 1;
  background-color: #fff;
  color: #37bd72;
}

.inverted.white {
  background-color: transparent;
  border-color: #fff;
  color: #fff;
}

.button.inverted.white:hover {
  opacity: 1;
  background-color: #fff;
  color: #000;
}

.inverted.red {
  background-color: transparent;
  border-color: #ff6f58;
  color: #ff6f58;
}

.button.inverted.red:hover {
  opacity: 1;
  background-color: #ff6f58;
  color: #fff;
}

@media (max-width: 500px) {
  .button {
    height: 42px;
  }
}
