.content {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 50%;
  height: 100%;
  z-index: 101;
  background: #2e2e2e;
  transition: 1s transform;
  display: flex;
  flex-direction: column;
  overflow: auto;
  transform: translateX(-100%);
}

.content.shown {
  transform: translateX(0%);
  transition: 0.2s ease-in transform;
}

.menuToggle {
  position: absolute;
  width: 40px;
  z-index: 102;
  padding: 20px;
}

.header {
  padding: 20px 40px 20px 80px;
  display: flex;
  justify-content: flex-end;
  opacity: 0;
  height: 72px;
  box-sizing: border-box;
}

.headerEnter {
  opacity: 0;
}

.headerEnterActive {
  opacity: 1;
  transition: opacity 0.5s 0.2s;
}

.headerEnterDone {
  opacity: 1;
}

.headerExit {
  opacity: 1;
}

.headerExitActive {
  opacity: 0;
  transition: opacity 0.5s;
}

.menu {
  flex: 1;
  overflow: auto;
  padding: 0 20px;
}

@media (max-width: 500px) {
  .content {
    width: 100%;
  }
}
