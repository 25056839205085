.container {
  width: 100%;
  height: 100%;
  overflow: auto;
}

.logo {
  margin-bottom: 36px;
}

.p {
  margin-bottom: 16px;
}

.list {
  padding: 0;
  list-style-position: inside;
  margin-bottom: 16px;
  color: #fff;
  margin-left: 24px;
}

.link {
  color: #fff;
  text-decoration: underline;
}
