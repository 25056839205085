.container {
  width: 100%;
  height: 100%;
  overflow: auto;
}

.logo {
  margin: 0 auto 36px;
  display: block;
}

.p {
  margin-bottom: 16px;
}

.link {
  color: #fff;
  text-decoration: underline;
}
