.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
}

.headline {
  margin-bottom: 60px;
  letter-spacing: 0.03em;
  display: flex;
  align-items: center;
}

.icon {
  margin-right: 12px;
  height: 24px;
}

.encrypted {
  letter-spacing: 0.03em;
  display: flex;
  align-items: center;
  align-self: center;
  margin-top: 36px;
}

.fileInfo {
  display: flex;
  align-items: center;
  justify-content: center;
}

.filename {
  flex: 1;
  margin-right: 12px;
}

.fileSize {
  flex-shrink: 0;
  margin-left: 12px;
}

.fileInfo + .fileInfo {
  border-top: solid 1px #fff;
}

.content {
  width: 100%;
  max-width: 420px;
  display: flex;
  flex-direction: column;
}

.download {
  margin-top: 24px;
}

.input {
  margin: 12px 0;
}

.action {
  margin-top: 36px;
  align-self: center;
  min-width: 120px;
}
