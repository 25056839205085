.container {
  display: block;
  height: 32px;
  width: 32px;
  position: relative;
}

.container span {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  height: 32px;
  width: 32px;
  box-sizing: border-box;
}

.container span::before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  height: 32px;
  width: 32px;
  border: 3px solid #fff;
  border-bottom: 3px solid transparent;
  border-radius: 50%;
  -webkit-animation: loader 1.5s cubic-bezier(0.77, 0, 0.175, 1) infinite;
  animation: loader 1.5s cubic-bezier(0.77, 0, 0.175, 1) infinite;
  box-sizing: border-box;
}

.container.white span::before {
  border-color: #fff;
  border-bottom-color: transparent;
}

.container.black span::before {
  border-color: #000;
  border-bottom-color: transparent;
}

@-webkit-keyframes loader {
  0% {
    -webkit-transform: rotate(0deg);
  }
  40% {
    -webkit-transform: rotate(180deg);
  }
  60% {
    -webkit-transform: rotate(180deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes loader {
  0% {
    transform: rotate(0deg);
  }
  40% {
    transform: rotate(180deg);
  }
  60% {
    transform: rotate(180deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.container span::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  width: 6px;
  height: 6px;
  background: #fff;
  border-radius: 50%;
  -webkit-animation: loader-2 1.5s cubic-bezier(0.77, 0, 0.175, 1) infinite;
  animation: loader-2 1.5s cubic-bezier(0.77, 0, 0.175, 1) infinite;
  box-sizing: border-box;
}

.container.white span::after {
  background: #fff;
}

.container.black span::after {
  background: #000;
}

@-webkit-keyframes loader-2 {
  0% {
    -webkit-transform: translate3d(0, -32px, 0) scale(0, 2);
    opacity: 0;
  }
  50% {
    -webkit-transform: translate3d(0, 0, 0) scale(1.25, 1.25);
    opacity: 1;
  }
  100% {
    -webkit-transform: translate3d(0, 8px, 0) scale(0, 0);
    opacity: 0;
  }
}

@keyframes loader-2 {
  0% {
    transform: translate3d(0, -32px, 0) scale(0, 2);
    opacity: 0;
  }
  50% {
    transform: translate3d(0, 0, 0) scale(1.25, 1.25);
    opacity: 1;
  }
  100% {
    transform: translate3d(0, 8px, 0) scale(0, 0);
    opacity: 0;
  }
}
