.container {
  width: 100%;
  padding: 12px 24px;
  background-color: rgba(0, 0, 0, 0.7);
  box-sizing: border-box;
}

.container + .container {
  border-top: solid 1px rgba(255, 255, 255, 0.2);
}
