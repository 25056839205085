.input {
  width: 100%;
  height: 42px;
  border-radius: 21px;
  color: #fff;
  letter-spacing: 0.09vw;
  background-color: transparent;
  border: 1px solid #ccc;
  padding: 8px 20px;
  box-sizing: border-box;
  outline: none;
  font-size: 16px;
}

.input.black {
  color: #000;
}

.input.black::placeholder {
  color: #000;
}

.input.white {
  color: #fff;
  border-color: #fff;
}

.input.white::placeholder {
  color: #fff;
}

.input[type='password'] {
  font-family: 'fds-pass';
  font-style: normal;
  font-weight: 400;
  speak: none;
  font-feature-settings: normal;
  font-variant: normal;
  text-transform: none;
  font-size: 14px;
}

.input[type='password']::placeholder {
  font-family: 'Lunchtype24-Light-Expanded';
  font-size: 16px;
}
