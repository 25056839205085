.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
}

.headline {
  margin-bottom: 60px;
  letter-spacing: 0.03em;
}

.row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #fff;
  padding: 6px 0;
  width: 100%;
  max-width: 470px;
}

.row + .row {
  border-top: solid 1px #fff;
}

.actions {
  margin-top: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 470px;
}

.label {
  flex-shrink: 0;
}

.filename {
  flex: 1;
  margin-left: 12px;
}
