.container {
  width: 100%;
  height: 100%;
  overflow: auto;
}

.headline {
  margin-bottom: 36px;
}

.p {
  margin-bottom: 16px;
}

.section {
  margin-bottom: 36px;
}
