.notification {
  display: flex;
  padding: 12px;
  min-height: 60px;
  border-radius: 12px;
  background: #0070ac url('./assets/decorator.svg') no-repeat left bottom / 60px 60px;
  overflow: visible;
  width: 30%;
  position: absolute;
  bottom: 24px;
  right: 24px;
  padding-left: 72px;
  padding-right: 36px;
  box-sizing: border-box;
  user-select: none;
}

.notification:before {
  display: inline-block;
  content: '';
  height: 42px;
  width: 42px;
  background: url('./assets/question_mark.svg') no-repeat center / 42px 42px;
  margin-top: -20px;
  position: absolute;
  top: 0;
  left: 18px;
}

.enter {
  opacity: 0;
  transform: translateX(100%);
}

.enterActive {
  opacity: 1;
  transform: translateX(0);
  transition: all 0.5s 0.2s;
}

.enterDone {
  opacity: 1;
  transform: translateX(0);
}

.exit {
  opacity: 1;
  transform: translateX(0);
}

.exitActive {
  opacity: 0;
  transform: translateX(100%);
  transition: all 0.5s;
}

.exitDone {
  opacity: 0;
  transform: translateX(100%);
}

.closeButton {
  position: absolute;
  top: 12px;
  right: 12px;
  background: transparent;
  border: none;
  cursor: pointer;
  transition: opacity 0.3s ease;
}

.closeButton:hover {
  opacity: 0.6;
}

@media (max-width: 800px) {
  .notification {
    width: 50%;
  }
}

@media (max-width: 500px) {
  .notification {
    right: 12px;
    width: calc(100% - 24px);
  }
}

@media (max-width: 350px) {
  .notification {
    background: #0070ac;
    padding: 12px 30px 12px;
  }

  .notification:before {
    display: none;
  }
}
