.link {
  text-decoration: none;
  transition: opacity 0.3s ease;
}

.link:hover {
  opacity: 0.7;
}

.link:focus,
.link:visited {
  color: inherit;
}
