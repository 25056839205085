.container {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.headline {
  margin-bottom: 24px;
}

.description {
  margin-bottom: 24px;
}

.loader {
  margin-top: 12px;
}
