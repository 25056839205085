.container {
  height: 100px;
  color: #fff;
  display: flex;
  align-items: flex-start;
}

.links {
  min-width: 180px;
  opacity: 0;
  transition: opacity 0.2s;
  display: flex;
  flex-direction: column;
  padding: 0 10px;
}

.linksOpened {
  opacity: 1;
}

.item {
  padding: 0 10px;
  text-decoration: none;
  transition: opacity 0.3s ease;
  color: #ccc;
  flex-shrink: 0;
  width: 100px;
}

.item.noItems {
  width: auto;
}

.item:hover {
  opacity: 0.7;
  text-decoration: none;
  color: #ccc;
}

.linkItem {
  margin-bottom: 10px;
  width: 57vw;
  text-decoration: none;
  transition: opacity 0.3s ease;
  color: #fff;
}

.linkItem:hover {
  opacity: 0.7;
  text-decoration: none;
  color: #fff;
}
