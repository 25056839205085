.container {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.content {
  flex: 1;
}

.headline {
  max-width: 16ch;
}

.actions {
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;

  transition: background-color 0.3s ease;
  margin: 0 -24px -24px;
  padding: 0 24px 24px;
  border-top: solid 1px transparent;
  margin-top: 30px;
}

.actions.hasFiles {
  background-color: #f3f3f3;
  border-color: rgba(38, 38, 38, 0.1);
  gap: 12px;
}

.actions.hasFiles .fileRow {
  border-bottom: solid 1px rgba(38, 38, 38, 0.1);
}

.fileRow {
  height: 60px;
  box-sizing: border-box;
  display: flex;

  align-items: center;
  padding: 12px 0;
  width: 100%;
}

.fileData {
  overflow: hidden;
}

.fileSize {
  opacity: 0.6;
}

.clearFile {
  margin-left: 12px;
  cursor: pointer;
  opacity: 0;
  border: none;
  background: transparent;
}

.fileRow:hover .clearFile {
  opacity: 1;
}

.dropArea {
  border: dashed 2px rgba(0, 0, 0, 0.6);
  border-radius: 6px;
  flex: 1;
  background-color: rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.folder {
  position: relative;
  width: 60px;
  height: calc(60px / 100 * 75);
  perspective: 300px;
  margin-bottom: 18px;
}

.folder span::before,
.folder span::after,
.folder::before,
.folder::after {
  position: absolute;
  content: '';
  border-radius: calc(60px / 100 * 5);
}

.folder::before {
  width: 100%;
  height: 92%;
  bottom: 0;
  background-color: #cca352;
}

.folder span::after {
  width: 90%;
  height: 85%;
  bottom: 0;
  left: 5%;
  background-color: #fff;
  z-index: 2;
  transition: transform 250ms ease 0ms;
  transform: translate3d(0px, 0px, 0px);
}

.folder span::before {
  background-color: #ffcc66;
  width: 100%;
  height: 92%;
  bottom: 0;
  left: 0;
  z-index: 3;
  content: '';
  transition: transform 500ms ease 0ms;
  transform-origin: 0 100% 0;
  outline: 1px solid transparent; /* Firefox anti-aliasing hack */
  transform-style: preserve-3d;
  display: flex;
  align-items: center;
  justify-content: center;
}

.folder::after {
  top: 0;
  left: 0;
  width: 40%;
  height: 50%;
  background-color: #cca352;
  z-index: 1;
}

.folder span::before {
  animation: openFolder 4s ease 0s infinite normal forwards;
}

@keyframes openFolder {
  0% {
    transform: rotateX(0);
  }

  30% {
    transform: rotateX(-35deg);
  }

  40%,
  100% {
    transform: rotateX(0);
  }
}

.uploadingMessage {
  gap: 12px;
  display: flex;
  flex-direction: column;
  flex: 1;
}

.progress {
  max-width: 100%;
  margin: 40px 0 0;
  width: 100%;
  align-self: flex-end;
}
