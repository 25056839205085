.container {
  width: 100%;
  height: 100vh;
}

.panel {
  position: relative;
  overflow: hidden;
  height: 100vh;
  animation: animate 4s linear infinite;
}

@keyframes animate {
  0% {
    filter: hue-rotate(0deg);
  }
  100% {
    filter: hue-rotate(360deg);
  }
}

.row {
  display: inline-flex;
  margin-left: -50px;
  margin-top: -32px;
  overflow: hidden;
}

.row:nth-child(even) {
  margin-left: 1px;
}

.logo {
  position: absolute;
  top: 24px;
  left: 24px;
  z-index: 999;
}

.content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  gap: 12px;
}

.headline {
  font-size: 160px;
}

@media (max-width: 500px) {
  .headline {
    font-size: 120px;
  }
}
