.container {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.instructions {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.instructions .features {
  margin-top: 25px;
  text-align: center;
}

.noWrap {
  display: inline-block;
}

.instructions h3.actions {
  line-height: 1.2;
  display: flex;
}

.actions {
  margin-top: 60px;
}

.instructions .selectIcon {
  height: 44px;
  top: -11px;
  position: relative;
  margin-right: 2px;
}

.instructions .dropIcon {
  height: 44px;
  top: -5px;
  position: relative;
  margin-right: 6px;
  margin-left: 13px;
}

.instructions .selectFileAction {
  text-decoration: underline;
  cursor: pointer;
  margin-right: 21px;
  pointer-events: all;
}

.options {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  box-sizing: border-box;
  background-color: #fb4a36;
  z-index: 104;
  display: flex;
  flex-wrap: wrap;
}
