.select :global(.Dropdown-control) {
  text-align: left;
  padding-left: 18px;
  background: transparent;
  border-radius: 30px;
  border: 1px solid #ccc;
  padding: 8px 20px;
  font-size: 16px;
  transition: unset;
  outline: none;
  box-sizing: border-box;
}

.white :global(.Dropdown-control) {
  color: #fff;
}

.black :global(.Dropdown-control) {
  color: #000;
}

.select :global(.Dropdown-placeholder) {
  overflow: hidden;
}

.select :global(.Dropdown-option) {
  text-align: left;
  padding: 8px 20px;
  font-size: 16px;
  overflow: hidden;
  transition: background-color 0.3s ease;
}

.select.white :global(.Dropdown-option) {
  color: #fff;
}

.select.black :global(.Dropdown-option) {
  color: #000;
}

.select:global(.Dropdown-option):global(.is-selected) {
  font-weight: 600;
}

.select :global(.Dropdown-option):hover {
  font-weight: 600;
  background-color: #ccc;
}

.select:global(.is-open) :global(.Dropdown-control) {
  border-radius: 20px 20px 0px 0px;
}

.select :global(.Dropdown-menu) {
  border-radius: 0 0 20px 20px;
}

.select.white .content :global(.Dropdown-menu) {
  background: white;
}

.select :global(.Dropdown-arrow) {
  border-color: #fff transparent;
  right: 18px;
  top: 50%;
  transform: translateY(-50%);
}

.select.black :global(.Dropdown-arrow) {
  border-color: #000 transparent;
}

.select :global(.is-open) :global(.Dropdown-arrow) {
  border-color: transparent transparent #fff;
}
