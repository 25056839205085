.container {
  height: 60px;
  padding: 12px 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

.item {
  width: 100%;
  max-width: 70px;
}

.github {
  max-width: 70px;
}

.twitter {
  max-width: 36px;
}

.datafund {
  max-width: 90px;
}

.glitter {
  max-width: 36px;
}

.riot {
  max-width: 36px;
}
