.container {
  transition: background-color 1s;
  display: flex;
  /* flex: 1; */
  /* min-width: 50%; */
  width: 100%;
  /* flex-grow: 1;
  flex-wrap: wrap; */

  box-sizing: border-box;
}

.wrapper {
  margin: 5px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  flex: 1;

  border: 1px solid white;
  border-radius: 5px;
}

.active {
  background: #ba2721;
}

.wrapper .headline {
  margin-bottom: 8px;
}

.wrapper .description {
  font-feature-settings: 'kern' off;
  font-kerning: none;
}
